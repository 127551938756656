<template>
  <div class="container">
    <!-- 头部 -->
    <Header :tab="3"/>
    <!-- 身体 -->
    <div class="main">
      <div class="banner wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <img src="../../assets/imgs/banner1.png" class="banner_img" alt />
        <div class="banner_box">
          <div class="banner_box_title">高端微信营销定制开发</div>
          <div class="banner_box_txt">丰富的开发经验，满足企业个性化营销需求，</div>
          <div class="banner_box_txt">提供更有效的互动体验，更好的为用户服务，提升品牌价值。</div>
        </div>
      </div>

      <!-- 主体 -->
      <div class="main_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <!-- 顶部 -->
        <div class="main_box_top wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
          <div class="main_box_top_r_item wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top" v-for="(item, index) in list" :key="index">
            <div class="main_box_top_r_img_box">
              <img :src="item.image" class="main_box_top_r_img" alt />
            </div>
            <div class="main_box_top_r_item_title">{{ item.title }}</div>
            <div class="main_box_top_r_item_txt">{{ item.summary }}</div>
          </div>
        </div>
        <!-- 定制 -->
        <div class="main_box_center wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
          :data-wow-offset="height_top">
          <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
            <div class="title_box_t">深度高端定制微信平台</div>
            <div class="title_box_text">让您的微信公众平台更具吸引力，针对行业及企业的个性化需求，定制开发利用最大化，用户体验最优化，生产管理做到精益求精为您创造更多的效益。</div>
          </div>
          <div class="main_box_center_box wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="main_box_center_item" v-if="list1.length > 0">
              <div class="main_box_center_item_img_box">
                <img :src="list1[0].image" class="main_box_center_item_img" alt />
              </div>
              <div class="main_box_center_item_box">
                <div class="main_box_center_item_title">{{ list1[0].title }}</div>
                <div class="main_box_center_item_txt">{{ list1[0].summary }}</div>
              </div>
              <div class="r_border"></div>
              <div class="b_border"></div>
            </div>
            <div class="main_box_center_item" v-if="list1.length > 1">
              <div class="main_box_center_item_img_box">
                <img :src="list1[1].image" class="main_box_center_item_img" alt />
              </div>
              <div class="main_box_center_item_box">
                <div class="main_box_center_item_title">{{ list1[1].title }}</div>
                <div class="main_box_center_item_txt">{{ list1[1].summary }}</div>
              </div>
              <div class="c_border"></div>
            </div>
            <div class="main_box_center_item" v-if="list1.length > 2">
              <div class="main_box_center_item_img_box">
                <img :src="list1[2].image" class="main_box_center_item_img" alt />
              </div>
              <div class="main_box_center_item_box">
                <div class="main_box_center_item_title">{{ list1[2].title }}</div>
                <div class="main_box_center_item_txt">{{ list1[2].summary }}</div>
              </div>
              <div class="q_border"></div>
            </div>
            <div class="main_box_center_item" v-if="list1.length > 3">
              <div class="main_box_center_item_img_box">
                <img :src="list1[3].image" class="main_box_center_item_img" alt />
              </div>
              <div class="main_box_center_item_box">
                <div class="main_box_center_item_title">{{ list1[3].title }}</div>
                <div class="main_box_center_item_txt">{{ list1[3].summary }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 营销 -->
        <div class="main_box_center_1 wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
          :data-wow-offset="height_top">
          <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
            <div class="title_box_t">多元化微信平台营销服务</div>
            <div class="title_box_text">以用户为中心，为客户提供多元化服务，以解决不同行业深度开发，满足更广泛的社会化营销需求。</div>
          </div>
          <div class="main_box_center_box wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="main_box_center_items" :style="{ height: tempHeight + 'px' }" ref="centerBox"
              v-for="(item, index) in list3" :key="index">
              <div class="main_box_center_item_img_box">
                <img :src="item.image" class="main_box_center_item_img" alt />
              </div>
              <div class="main_box_center_item_txt">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <!-- 推荐 -->
        <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
          <div>推荐案例</div>
          <div class="title_box_text">眼光高度决定品牌厚度！</div>
        </div>
        <div class="main_box_center_btn wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
          <div @click="toDetails(item)" v-for="(item, index) in list2" :key="index"
            class="main_box_center_btn_box wow animate__slideInUp" :data-wow-delay="index / 10 + 0.2 + 's'"
            data-wow-iteration="1" :data-wow-offset="height_top">
            <div class="main_box_center_btn_item">
              <div class="main_box_center_btn_item_img_box" ref="btnItem" :style="{ height: tempHeight + 'px' }">
                <img :src="item.list_image1" class="main_box_center_btn_item_img" alt />
              </div>
              <div class="main_box_center_btn_item_box">
                <div class="main_box_center_btn_item_box_title">{{ item.title }}</div>
                <div class="main_box_center_btn_item_box_txt">{{ item.summary }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer class="wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top" />
  </div>
</template>
  
<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import storage from "@/utils/storage";
import {
  articlellist17,
  articlellist18,
  articlellist19,
  projectsrecommends
} from "@/api/axios/axios.api";

import { WOW } from "wowjs";
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      height_top: storage.height_top,
      tempHeight: "",
      tempHeights: "",
      timer: null,
      list: [],
      list1: [],
      list2: [],
      list3: []
    };
  },
  methods: {
    // 案例详情
    toDetails(e) {
      this.$router.push({
        name: "sucsessdetails",
        query: {
          id: e.id
        }
      });
    },
    // 设置高度
    height(e) {
      //进入页面元素默认宽度
      this.timer = setInterval(() => {
        if (!this.tempHeight || !this.tempHeights) {
          if (e == 2) {
            if (this.$refs.centerBox) {
              this.tempHeight = this.$refs.centerBox[0].offsetWidth;
            }
          } else if (e == 1) {
            if (this.$refs.btnItem) {
              this.tempHeights = this.$refs.btnItem[0].offsetWidth;
            }
          }
          clearInterval(this.timer)
          this.timer = null
        } else {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
      //根据屏幕缩放自动获取页面宽度
      window.onresize = () => {
        return (() => {
          //窗口缩放自动获取元素宽度
          if (e == 2) {
            this.tempHeight = this.$refs.centerBox[0].offsetWidth; //宽度
          } else if (e == 1) {
            this.tempHeights = this.$refs.btnItem[0].offsetWidth; //宽度
          }
        })();
      };
    },
    // 获取数据
    async getAdlist() {
      // 获取定制介绍
      let res = await articlellist17();
      this.list = res.data.list;
      // 获取定制功能说明
      let rec = await articlellist18();
      this.list1 = rec.data.list;
      // 案例推荐
      let ret = await projectsrecommends(2);
      this.list2 = ret.data.list;
      if (ret.data.list.length) {
        this.height(1);
      }
      // 获取平台营销服务
      let rex = await articlellist19();
      this.list3 = rex.data.list;
      if (rex.data.list.length) {
        this.height(2);
      }
    },
    // 缩放比例
    resize() {
      // 系统整体缩放
      let cliWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      let cliHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let contW = 1920;
      let contH = 1080;
      let w = cliWidth / contW;
      let h = cliHeight / contH;
      let appDom = document.querySelector(".container");
      appDom.style.transform = "scale(" + w + "," + h + ")";
      appDom.style.transformOrigin = "top left";
      appDom.style.width = contW + "px";
      appDom.style.height = contH + "px";
    }
  },
  created() { },
  mounted() {
    this.getAdlist();

    this.$nextTick(() => {
      //   this.resize();
      //   window.onresize = function() {
      //     this.resize();
      //   }.bind(this);

      let wow = new WOW({
        live: true
      });
      wow.init();
    });
  },
  watch: {},
  beforeDestroy() {
    console.log("销毁定时器");
    clearInterval(this.timer);
  },
};
</script>
  
<style lang="less" scoped>
@media screen and (min-width : 1400px) {
  .banner {
    color: #fff;
    // height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .banner_img {
      // position: absolute;
      // height: 800px;
      width: 100%;
    }

    .banner_box {
      padding: 0 10%;
      position: absolute;
      z-index: 1;

      .banner_box_title {
        font-size: 70px;
        font-weight: bold;
        margin-bottom: 130px;
      }

      .banner_box_txt {
        font-size: 30px;
        margin-bottom: 40px;
      }
    }
  }

  // 身体
  .main_box {
    background-color: #fff;

    // 头部
    .main_box_top {
      padding: 80px 10%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .main_box_top_r_item {
        width: calc(96% / 2);
        padding: 2%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        color: #999;
        font-size: 16px;
        transition: all 0.5s;

        .main_box_top_r_img_box {
          max-width: 680px;
          max-height: 382px;
          // overflow: hidden;

          .main_box_top_r_img {
            width: 100%;
            transition: all 0.5s;
          }
        }

        .main_box_top_r_item_title {
          font-size: 30px;
          color: #333333;
          margin: 30px 0;
          text-align: center;
        }

        .main_box_top_r_item_txt {
          padding: 0 10%;
          font-size: 16px;
          color: #999999;
          line-height: 34px;
          text-align: center;
        }
      }

      .main_box_top_r_item:nth-child(1) {
        margin-right: 5%;
      }

      // .main_box_top_r_item:hover {
      //     transition: all 0.5s;
      //     box-shadow: 0 0 20px rgba(153, 153, 153, 1);
      // }

      .main_box_top_r_item:hover .main_box_top_r_img {
        transform: scale(1.1);
        transition: all 0.5s;
        // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
      }
    }

    // 定制
    .main_box_center {
      padding: 0 10%;
      // height: 919px;
      padding-bottom: 8%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url("../../assets/imgs/bg11.jpg") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;

      .title_box {
        width: 706px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 28px;
        background: transparent;

        .title_box_t {
          color: #ffffff;
          font-size: 32px;
        }

        .title_box_text {
          color: #ffffff;
          margin-top: 30px;
          font-weight: normal;
          font-size: 16px;
          text-align: center;
          line-height: 34px;
        }
      }

      .main_box_center_box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        //   background-color: #fff;
        width: 1220px;
        //   background: #ffffff;

        .main_box_center_item {
          width: calc(100% / 2);
          height: 220px;
          padding: 60px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          transition: all 0.5s;
          position: relative;
          background: #ffffff;

          .main_box_center_item_img_box {
            display: flex;
            justify-content: center;
            align-items: center;
            // overflow: hidden;
            width: 62px;
            height: 62px;
            transition: all 0.5s;
            margin-right: 70px;

            .main_box_center_item_img {
              width: 62px;
              height: 62px;
              transition: all 0.5s;
            }
          }

          .main_box_center_item_box {
            flex: 1;

            .main_box_center_item_title {
              color: #333333;
              font-size: 20px;
              margin-bottom: 10px;
            }

            .main_box_center_item_txt {
              color: #999999;
              font-size: 14px;
              line-height: 22px;
              transition: all 0.5s;
            }
          }

          .r_border {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background: #dcdcdc;
            background-image: linear-gradient(to bottom, #fff, #dcdcdc);
          }

          .b_border {
            height: 1px;
            width: 100%;
            background: #dcdcdc;
            position: absolute;
            left: 0;
            bottom: 0;
            background-image: linear-gradient(to right, #fff 10%, #dcdcdc 90%);
          }

          .c_border {
            height: 1px;
            width: 100%;
            background: #dcdcdc;
            position: absolute;
            left: 0;
            bottom: 0;
            background-image: linear-gradient(to left, #fff 10%, #dcdcdc 90%);
          }

          .q_border {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background: #dcdcdc;
            background-image: linear-gradient(to top, #fff, #dcdcdc);
          }
        }

        .main_box_center_item:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .main_box_center_item:hover .main_box_center_item_img {
          transform: scale(1.5);
          transition: all 0.5s;
        }
      }
    }

    // 营销
    .main_box_center_1 {
      padding: 0 10%;
      // height: 919px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      overflow: hidden;
      padding-bottom: 150px;
      background-image: linear-gradient(to bottom, #fff 10%, #cfd9e3 90%);

      .title_box {
        width: 706px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        background: transparent;

        .title_box_t {
          color: #333333;
          font-size: 32px;
        }

        .title_box_text {
          color: #999999;
          margin-top: 30px;
          font-weight: normal;
          font-size: 16px;
          text-align: center;
          line-height: 34px;
        }
      }

      .main_box_center_box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        position: relative;
        margin-right: -10px;

        .main_box_center_items {
          width: calc(100% / 8);
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          transition: all 0.5s;
          border: 1px solid #dcdcdc;
          margin: 0 -1px -1px 0;

          .main_box_center_item_img_box {
            display: flex;
            justify-content: center;
            align-items: center;
            // overflow: hidden;
            width: 39px;
            height: 39px;
            margin-bottom: 38px;
            transition: all 0.5s;

            .main_box_center_item_img {
              width: 39px;
              height: 39px;
              transition: all 0.5s;
            }
          }

          .main_box_center_item_txt {
            color: #999999;
            font-size: 14px;
            line-height: 22px;
            transition: all 0.5s;
          }
        }

        .main_box_center_items:hover .main_box_center_item_txt {
          font-weight: bold;
          transition: all 0.5s;
        }

        .main_box_center_items:hover {
          z-index: 100;
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .main_box_center_items:hover .main_box_center_item_img {
          transform: scale(1.5);
          transition: all 0.5s;
        }
      }
    }

    // 推荐
    .title_box {
      padding: 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #333333;
      font-size: 28px;
      background-color: #f5f5f5;

      .title_box_text {
        margin-top: 21px;
        font-weight: normal;
        color: #666666;
        font-size: 18px;
      }
    }

    .main_box_center_btn {
      padding: 0 10%;
      padding-bottom: 66px;
      display: flex;
      justify-content: space-between;
      background-color: #f5f5f5;
      align-items: center;

      .main_box_center_btn_box {
        width: calc(100% / 3);
        display: flex;
        align-items: center;
        justify-content: center;

        .main_box_center_btn_item {
          // width: 407px;
          width: 100%;
          // height: 514px;
          background: #ffffff;
          padding: 2%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          transition: all 0.5s;

          .main_box_center_btn_item_img_box {
            width: 100%;
            // height: 378px;
            overflow: hidden;

            .main_box_center_btn_item_img {
              width: 100%;
              height: 100%;
              transition: all 0.5s;
            }
          }

          .main_box_center_btn_item_box {
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 8% 0;

            .main_box_center_btn_item_box_title {
              color: #333333;
              font-size: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 5%;
            }

            .main_box_center_btn_item_box_txt {
              color: #999999;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
          }
        }

        .main_box_center_btn_item:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .main_box_center_btn_item:hover .main_box_center_btn_item_img {
          transform: scale(1.5);
          transition: all 0.5s;
        }
      }

      .main_box_center_btn_box:nth-child(2) {
        margin: 0 5%;
      }
    }
  }
}

@media screen and (max-width : 1399px) {
  .banner {
    min-width: 1300px;
    color: #fff;
    // height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .banner_img {
      // position: absolute;
      // height: 800px;
      width: 100%;
    }

    .banner_box {
      padding: 0 20px;
      position: absolute;
      z-index: 1;

      .banner_box_title {
        font-size: 70px;
        font-weight: bold;
        margin-bottom: 130px;
      }

      .banner_box_txt {
        font-size: 30px;
        margin-bottom: 40px;
      }
    }
  }

  // 身体
  .main_box {
    min-width: 1300px;
    background-color: #fff;

    // 头部
    .main_box_top {
      padding: 80px 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .main_box_top_r_item {
        width: calc(98% / 2);
        padding: 2%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        color: #999;
        font-size: 16px;
        transition: all 0.5s;

        .main_box_top_r_img_box {
          max-width: 680px;
          max-height: 382px;
          // overflow: hidden;

          .main_box_top_r_img {
            width: 100%;
            transition: all 0.5s;
          }
        }

        .main_box_top_r_item_title {
          font-size: 30px;
          color: #333333;
          margin: 30px 0;
          text-align: center;
        }

        .main_box_top_r_item_txt {
          padding: 0 10%;
          font-size: 16px;
          color: #999999;
          line-height: 34px;
          text-align: center;
        }
      }

      .main_box_top_r_item:nth-child(1) {
        margin-right: 2%;
      }

      // .main_box_top_r_item:hover {
      //     transition: all 0.5s;
      //     box-shadow: 0 0 20px rgba(153, 153, 153, 1);
      // }

      .main_box_top_r_item:hover .main_box_top_r_img {
        transform: scale(1.1);
        transition: all 0.5s;
        // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
      }
    }

    // 定制
    .main_box_center {
      padding: 0 20px;
      // height: 919px;
      padding-bottom: 8%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url("../../assets/imgs/bg11.jpg") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;

      .title_box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 28px;
        background: transparent;

        .title_box_t {
          color: #ffffff;
          font-size: 32px;
        }

        .title_box_text {
          color: #ffffff;
          margin-top: 30px;
          font-weight: normal;
          font-size: 16px;
          text-align: center;
          line-height: 34px;
        }
      }

      .main_box_center_box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        //   background-color: #fff;
        // width: 1220px;
        //   background: #ffffff;

        .main_box_center_item {
          width: calc(100% / 2);
          height: 220px;
          padding: 60px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          transition: all 0.5s;
          position: relative;
          background: #ffffff;

          .main_box_center_item_img_box {
            display: flex;
            justify-content: center;
            align-items: center;
            // overflow: hidden;
            width: 62px;
            height: 62px;
            transition: all 0.5s;
            margin-right: 70px;

            .main_box_center_item_img {
              width: 62px;
              height: 62px;
              transition: all 0.5s;
            }
          }

          .main_box_center_item_box {
            flex: 1;

            .main_box_center_item_title {
              color: #333333;
              font-size: 20px;
              margin-bottom: 10px;
            }

            .main_box_center_item_txt {
              color: #999999;
              font-size: 14px;
              line-height: 22px;
              transition: all 0.5s;
            }
          }

          .r_border {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background: #dcdcdc;
            background-image: linear-gradient(to bottom, #fff, #dcdcdc);
          }

          .b_border {
            height: 1px;
            width: 100%;
            background: #dcdcdc;
            position: absolute;
            left: 0;
            bottom: 0;
            background-image: linear-gradient(to right, #fff 10%, #dcdcdc 90%);
          }

          .c_border {
            height: 1px;
            width: 100%;
            background: #dcdcdc;
            position: absolute;
            left: 0;
            bottom: 0;
            background-image: linear-gradient(to left, #fff 10%, #dcdcdc 90%);
          }

          .q_border {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background: #dcdcdc;
            background-image: linear-gradient(to top, #fff, #dcdcdc);
          }
        }

        .main_box_center_item:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .main_box_center_item:hover .main_box_center_item_img {
          transform: scale(1.5);
          transition: all 0.5s;
        }
      }
    }

    // 营销
    .main_box_center_1 {
      padding: 0 20px;
      // height: 919px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      overflow: hidden;
      padding-bottom: 150px;
      background-image: linear-gradient(to bottom, #fff 10%, #cfd9e3 90%);

      .title_box {
        width: 706px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        background: transparent;

        .title_box_t {
          color: #333333;
          font-size: 32px;
        }

        .title_box_text {
          color: #999999;
          margin-top: 30px;
          font-weight: normal;
          font-size: 16px;
          text-align: center;
          line-height: 34px;
        }
      }

      .main_box_center_box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        position: relative;
        margin-right: -10px;

        .main_box_center_items {
          width: calc(100% / 8);
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          transition: all 0.5s;
          border: 1px solid #dcdcdc;
          margin: 0 -1px -1px 0;

          .main_box_center_item_img_box {
            display: flex;
            justify-content: center;
            align-items: center;
            // overflow: hidden;
            width: 39px;
            height: 39px;
            margin-bottom: 38px;
            transition: all 0.5s;

            .main_box_center_item_img {
              width: 39px;
              height: 39px;
              transition: all 0.5s;
            }
          }

          .main_box_center_item_txt {
            color: #999999;
            font-size: 14px;
            line-height: 22px;
            transition: all 0.5s;
          }
        }

        .main_box_center_items:hover .main_box_center_item_txt {
          font-weight: bold;
          transition: all 0.5s;
        }

        .main_box_center_items:hover {
          z-index: 100;
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .main_box_center_items:hover .main_box_center_item_img {
          transform: scale(1.5);
          transition: all 0.5s;
        }
      }
    }

    // 推荐
    .title_box {
      padding: 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #333333;
      font-size: 28px;
      background-color: #f5f5f5;

      .title_box_text {
        margin-top: 21px;
        font-weight: normal;
        color: #666666;
        font-size: 18px;
      }
    }

    .main_box_center_btn {
      padding: 0 20px;
      padding-bottom: 66px;
      display: flex;
      justify-content: space-between;
      background-color: #f5f5f5;
      align-items: center;

      .main_box_center_btn_box {
        width: calc(100% / 3);
        display: flex;
        align-items: center;
        justify-content: center;

        .main_box_center_btn_item {
          // width: 407px;
          width: 100%;
          // height: 514px;
          background: #ffffff;
          padding: 2%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          transition: all 0.5s;

          .main_box_center_btn_item_img_box {
            width: 100%;
            // height: 378px;
            overflow: hidden;

            .main_box_center_btn_item_img {
              width: 100%;
              height: 100%;
              transition: all 0.5s;
            }
          }

          .main_box_center_btn_item_box {
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 8% 0;

            .main_box_center_btn_item_box_title {
              color: #333333;
              font-size: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 5%;
            }

            .main_box_center_btn_item_box_txt {
              color: #999999;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
          }
        }

        .main_box_center_btn_item:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .main_box_center_btn_item:hover .main_box_center_btn_item_img {
          transform: scale(1.5);
          transition: all 0.5s;
        }
      }

      .main_box_center_btn_box:nth-child(2) {
        margin: 0 1%;
      }
    }
  }
}
</style>
  